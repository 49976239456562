import React, { useContext } from "react";
import { Logout, ToastMessage } from "../../../../utils/Helper";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../../../reuseable/Breadcrumb/Breadcrumb";
import {
  RIDER_,
  RIDER_ITEM,
  SLACE,
} from "../../../../reuseable/Breadcrumb/BreadcrumbConstant";
import RiderDataTabelContainer from "./RiderDataTabelContainer";
import RiderContext from "../../../../context/RiderContext/RiderContext";
import { get_Rider } from "../../../../Apis/riderApi/RiderApi";

const RidersList = () => {
  const {
    //pagination state variables
    pageSize,
    page,
    setRecord,
    sortOrder,
    sortBy,
    filter,
  } = useContext(RiderContext);

  const history = useNavigate();

  /**
   * get rider List
   */

  let paramsObj = {
    pageNumber: page,
    recordsPerPage: pageSize,
    sortOrder,
    sortBy,
  };

  if (filter.length) {
    paramsObj["search"] = JSON.stringify({ name: filter });
  }

  let { data, refetch, isLoading, error } = useQuery(
    ["getRider", paramsObj],
    async () => await get_Rider(paramsObj),
    {
      onSuccess: (response) => {
        if (response.status === 200) {
          setRecord(response.pager.totalRecords);
        }
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  if (error) {
    const { status, message } = error?.response?.data || error;
    ToastMessage(status, message);
    Logout(history, status);
  }

  return (
    <>
      <Breadcrumb name={RIDER_} slace={SLACE} name1={RIDER_ITEM} />

      <RiderDataTabelContainer
        isLoading={isLoading}
        data={data}
        refetch={refetch}
      />
    </>
  );
};

export default RidersList;
