import React, { useContext } from "react";
import { Logout, ToastMessage } from "../../../../utils/Helper";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../../../reuseable/Breadcrumb/Breadcrumb";
import {
  BANNER_SLIDER_,
  BANNER_SLIDER_ITEM,
  SLACE,
} from "../../../../reuseable/Breadcrumb/BreadcrumbConstant";
import BannerSliderDataTabelContainer from "./BannerSliderDataTabelContainer";
import BannerSliderContext from "../../../../context/BannerSliderContext/BannerSliderContext";
import { get_Banner_Slider } from "../../../../Apis/bannerSliderApi/BannerSliderApi";
import { BackButton } from "../../../../reuseableComponents/buttons";
import CssContext from "../../../../context/css_context/CssContext/CssContext";

const BannerSlider = () => {
  const {
    //pagination state variables
    pageSize,
    page,
    setRecord,
    sortOrder,
    sortBy,
    filter,
  } = useContext(BannerSliderContext);
  const { isDashboardRedirect, setIsDashboardRedirect } =
    useContext(CssContext);

  const history = useNavigate();

  /**
   * get banner slider List
   */

  let paramsObj = {
    pageNumber: page,
    recordsPerPage: pageSize,
    sortOrder,
    sortBy,
    search: JSON.stringify({ type: 1, name: filter }),
  };

  let { data, refetch, isLoading, error } = useQuery(
    ["getBannerSlider", paramsObj],
    async () => await get_Banner_Slider(paramsObj),
    {
      onSuccess: (response) => {
        if (response.status === 200) {
          setRecord(response.pager.totalRecords);
        }
      },
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  if (error) {
    const { status, message } = error?.response?.data || error;
    ToastMessage(status, message);
    Logout(history, status);
  }

  const prev = () => {
    setIsDashboardRedirect({
      isShow: false,
      redirect: "",
    });
  };

  return (
    <>
      <Breadcrumb
        name={BANNER_SLIDER_}
        slace={SLACE}
        name1={BANNER_SLIDER_ITEM}
      />
      {isDashboardRedirect?.isShow && (
        <BackButton
          history={history}
          path={isDashboardRedirect.redirect}
          otherFunc={prev}
        />
      )}
      <BannerSliderDataTabelContainer
        isLoading={isLoading}
        data={data}
        refetch={refetch}
        type={"slider"}
      />
    </>
  );
};

export default BannerSlider;
