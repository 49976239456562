import React, { useContext } from "react";
import { Logout, ToastMessage } from "../../../../utils/Helper";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import DeliverySlotDataTabelContainer from "./DeliverySlotDataTabelContainer";
import DeliverySlotCreateFormPopup from "./DeliverySlotCreateFormPopup";
import Breadcrumb from "../../../../reuseable/Breadcrumb/Breadcrumb";
import {
  SLACE,
  DELIVERY_SLOT_,
  DELIVERY_SLOT_LIST,
} from "../../../../reuseable/Breadcrumb/BreadcrumbConstant";
import Modal from "../../../../reuseableComponents/Modal";
import { CreateDeliverySlot, UpdateDeliverySlot } from "../../../../constants";
import { get_Delivery_Slot } from "../../../../Apis/deliverySlotApi/DeliverySlotApi";
import DeliverySlotContext from "../../../../context/DeliverySlotContext/DeliverySlotContext";

const DeliverySlot = () => {
  const {
    //pagination state variables
    pageSize,
    page,
    setRecord,
    sortOrder,
    sortBy,
    filter,

    //delivery slot edit condition state and data object state
    setdialogs,
    dialog,
    setIsEdit,
    isEdit,
    setFormData,
  } = useContext(DeliverySlotContext);

  const emptyFormData = () => {
    setFormData({
      slotName: "",
      storeId: "",
      startTime: "",
      endTime: "",
      order: "",
    });
  };

  const history = useNavigate();

  /**
   * get delivery slot List
   */

  let paramsObj = {
    pageNumber: page,
    recordsPerPage: pageSize,
    sortOrder,
    sortBy,
  };
  if (filter.length) {
    paramsObj["search"] = JSON.stringify({ slotName: filter });
  }

  let { data, refetch, isLoading, error } = useQuery(
    ["getDeliverySlot", paramsObj],
    async () => await get_Delivery_Slot(paramsObj),
    {
      onSuccess: (response) => {
        if (response.status === 200) {
          setRecord(response.pager.totalRecords);
        }
      },
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      refetchOnReconnect: false,
      retry: 3,
    }
  );

  if (error) {
    const { status, message } = error?.response?.data || error;
    ToastMessage(status, message);
    Logout(history, status);
  }

  /**
   * Open Popup and Close Popup delivery slot
   */
  const handleOpenandClosePopup = (condition) => {
    setIsEdit(true);
    if (condition) {
      setdialogs(condition);
      emptyFormData();
    } else {
      setdialogs(condition);
      emptyFormData();
    }
  };
  return (
    <>
      <Breadcrumb
        name={DELIVERY_SLOT_}
        slace={SLACE}
        name1={DELIVERY_SLOT_LIST}
      />
      <DeliverySlotDataTabelContainer
        isLoading={isLoading}
        data={data}
        handleOpenandClosePopup={handleOpenandClosePopup}
        refetch={refetch}
      />
      <Modal
        hide={handleOpenandClosePopup}
        visible={dialog}
        header={isEdit ? CreateDeliverySlot : UpdateDeliverySlot}
        Component={
          dialog && (
            <DeliverySlotCreateFormPopup
              refetch={refetch}
              emptyFormData={emptyFormData}
            />
          )
        }
      />
    </>
  );
};

export default DeliverySlot;
