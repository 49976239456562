import React, { useContext } from "react";
import { Logout, ToastMessage, setValueNull } from "../../../../utils/Helper";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import BrandMasterDataTabelContainer from "./BrandMasterDataTabelContainer";
import BrandMasterCreateFormPopup from "./BrandMasterCreateFormPopup";
import Breadcrumb from "../../../../reuseable/Breadcrumb/Breadcrumb";
import {
  BRAND,
  BRAND_LIST,
  SLACE,
} from "../../../../reuseable/Breadcrumb/BreadcrumbConstant";
import BrandMasterContext from "../../../../context/BrandMasterContext/BrandMasterContext";
import { get_Brand_Master } from "../../../../Apis/brandMasterApi/BrandMasterApi";
import Modal from "../../../../reuseableComponents/Modal";
import { CreateBrand, UpdateBrand } from "../../../../constants";
import CssContext from "../../../../context/css_context/CssContext/CssContext";
import { BackButton } from "../../../../reuseableComponents/buttons";

const BrandMaster = () => {
  const {
    //pagination state variables
    pageSize,
    page,
    setRecord,
    sortOrder,
    sortBy,
    filter,

    //brand master edit condition state and data object state
    setdialogs,
    dialog,
    setIsEdit,
    isEdit,
    setFormData,
    setErrors,
  } = useContext(BrandMasterContext);
  const { isDashboardRedirect, setIsDashboardRedirect } =
    useContext(CssContext);

  const history = useNavigate();

  /**
   * get brand master List
   */

  let paramsObj = {
    pageNumber: page,
    recordsPerPage: pageSize,
    sortOrder,
    sortBy,
  };
  if (filter.length) {
    paramsObj["search"] = JSON.stringify({ brandName: filter });
  }

  let { data, refetch, isLoading, error } = useQuery(
    ["getBrandMaster", paramsObj],
    async () => await get_Brand_Master(paramsObj),
    {
      onSuccess: (response) => {
        if (response.status === 200) {
          setRecord(response.pager.totalRecords);
        }
      },
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      refetchOnReconnect: false,
      retry: 3,
    },
    {}
  );

  if (error) {
    const { status, message } = error?.response?.data || error;
    ToastMessage(status, message);
    Logout(history, status);
  }

  /**
   * Open Popup and Close Popup brand master
   */
  const handleOpenandClosePopup = (condition) => {
    setIsEdit(true);
    if (condition) {
      setdialogs(condition);
      setFormData((prev) => setValueNull(prev));
      setErrors({});
    } else {
      setdialogs(condition);
      setFormData((prev) => setValueNull(prev));
      setErrors({});
    }
  };

  const prev = () => {
    setIsDashboardRedirect({
      isShow: false,
      redirect: "",
    });
  };
  return (
    <>
      <Breadcrumb name={BRAND} slace={SLACE} name1={BRAND_LIST} />
      {isDashboardRedirect?.isShow && (
        <BackButton
          history={history}
          path={isDashboardRedirect.redirect}
          otherFunc={prev}
        />
      )}
      <BrandMasterDataTabelContainer
        isLoading={isLoading}
        data={data}
        handleOpenandClosePopup={handleOpenandClosePopup}
        refetch={refetch}
      />
      <Modal
        hide={handleOpenandClosePopup}
        visible={dialog}
        header={isEdit ? CreateBrand : UpdateBrand}
        Component={<BrandMasterCreateFormPopup refetch={refetch} />}
      />
    </>
  );
};

export default BrandMaster;
