import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "../assets/css/Navbar.css";
import UserContext from "../context/UserContext/UserContext";
import CssContext from "../context/css_context/CssContext/CssContext";
import Footer from "../layouts/footer";
import Loader from "../layouts/loader";
import { Role } from "../constants";
import { REDIRECT_PATH } from "../Routes";
import {
  DASHBOARD_ITEM,
  DASHBOARD_TITLE,
  USER_ITEM,
  USER_TITLE,
  BRAND_TITLE,
  BRAND_ITEM,
  ORDERS,
  ORDERS_ITEM,
  PRODUCTS,
  PRODUCTS_ITEM,
  PRODUCTS_ADD,
  CATEGORY,
  CATEGORY_ITEM,
  CATEGORY_ADD,
  STORE_TITLE,
  STORE_ITEM,
  PRODUCTS_STOCK__ADD,
  PRODUCTS_STOCK_ITEM,
  BANNER_SLIDER_ITEM,
  BANNER_BANNER_LIST,
  BANNER_SLIDER,
  BANNER_SLIDER_ADD,
  ORDER_SETTING,
  ORDER_sETTING_LIST,
  PRODUCTS_TAG_ITEM,
  PRODUCTS_TAG_ADD,
  DETAILS_PAGE_TITLE,
  DETAILS_PAGE_ITEM,
  FEATURE_,
  FEATURE_ITEM,
  INQUIRY,
  INQUIRY_ITEM,
  RIDER,
  RIDER_LIST,
  RIDER_ADD,
  PROMOCODE,
  PROMOCODE_LIST,
  PROMOCODE_ADD,
  DEALOFTHEDAY,
  DEALOFTHEDAY_LIST,
  DEALOFTHEDAY_ADD,
  DELIVERY_SLOT,
  DELIVERY_SLOT_LIST,
  DELIVERY_OFF_DAY_,
  DELIVERY_OFF_DAY_LIST_,
  ATTRIBUTE_,
  ATTRIBUTE_ITEM,
  ATTRIBUTE_ITEM_,
  CATEGORY_ATTRIBUTE_ITEM_,
  PINCODE,
  PINCODE_LIST_,
  DELIVERY_PINCODE_LIST,
  DETAILS_MASTER_PAGE_ITEM,
} from "../reuseable/Breadcrumb/BreadcrumbConstant";
import { useDispatch, useSelector } from "react-redux";
import {
  dopdownOpen,
  dropdownClose,
} from "../redux/slices/headerDropdown/headerDropdown";
import { GetRole } from "../utils/Helper";
import { checkAndShowSidebar } from "../Routes/helper";

const Navbar = (props) => {
  const { windowSize, setMainDivSidebar, maindivSidebar } = props;
  const history = useNavigate();
  const { yes, setYes } = useContext(UserContext);
  /** redux */
  const headerDropdown = useSelector((state) => state.header_dropdown);
  const dispatch = useDispatch();
  const {
    setMobileSidebar,
    setMobileSidebarOpenAndClose,
    mobileSidebarOpenAndClose,
    darkMode,
    setDarkMode,
  } = useContext(CssContext);
  const [isLoading, setIsLoading] = useState(true);
  const [SidebarMenu, setSibarMenu] = useState(
    checkAndShowSidebar()
      ? [
          {
            title: DASHBOARD_TITLE,
            items: [
              {
                name: DASHBOARD_ITEM,
                iconClassName: "pi pi-th-large",
                to: REDIRECT_PATH.DASHBOARD,
                isMenuActive: false,
              },
            ],
          },
          {
            title: USER_TITLE,
            items: [
              {
                name: USER_ITEM,
                iconClassName: "pi pi-user-plus",
                to: REDIRECT_PATH.USER,
                isMenuActive: false,
              },
            ],
          },
          {
            title: BRAND_TITLE,
            items: [
              {
                name: BRAND_ITEM,
                iconClassName: "pi pi-ticket",
                to: REDIRECT_PATH.BRAND_MASTER,
                isMenuActive: false,
              },
            ],
          },
          {
            title: ORDERS,
            items: [
              {
                name: ORDERS_ITEM,
                iconClassName: "pi pi-shopping-cart",
                to: REDIRECT_PATH.ORDERS,
                isMenuActive: false,
              },
            ],
          },
          {
            title: ATTRIBUTE_,
            items: [
              {
                name: ATTRIBUTE_ITEM,
                iconClassName: "pi pi-sitemap",
                to: REDIRECT_PATH.ATTRIBUTE_PATH,
                isMenuActive: false,
              },
              {
                name: ATTRIBUTE_ITEM_,
                iconClassName: "pi pi-sitemap",
                to: REDIRECT_PATH.ATTRIBUTE_PATH_,
                isMenuActive: false,
              },
              {
                name: CATEGORY_ATTRIBUTE_ITEM_,
                iconClassName: "pi pi-sitemap",
                to: REDIRECT_PATH.CATEGORY_ATTRIBUTE_MAP_PATH_,
                isMenuActive: false,
              },
            ],
          },
          {
            title: PRODUCTS,
            items: [
              {
                name: PRODUCTS_ITEM,
                iconClassName: "pi pi-shopping-bag",
                to: REDIRECT_PATH.PRODUCTS,
                isMenuActive: false,
              },
              {
                name: PRODUCTS_ADD,
                iconClassName: "pi pi-plus",
                to: "/products/add",
                isMenuActive: false,
              },
              {
                name: PRODUCTS_STOCK_ITEM,
                iconClassName: "pi pi-chart-bar",
                to: REDIRECT_PATH.PRODUCTS_STOCK,
                isMenuActive: false,
              },
              {
                name: PRODUCTS_STOCK__ADD,
                iconClassName: "pi pi-plus",
                to: `${REDIRECT_PATH.PRODUCTS_STOCK}/add`,
                isMenuActive: false,
              },
              {
                name: PRODUCTS_TAG_ITEM,
                iconClassName: "pi pi-tag",
                to: REDIRECT_PATH.PRODUCTS_TAG,
                isMenuActive: false,
              },
              {
                name: PRODUCTS_TAG_ADD,
                iconClassName: "pi pi-plus",
                to: `${REDIRECT_PATH.PRODUCTS_TAG}/add`,
                isMenuActive: false,
              },
            ],
          },
          {
            title: CATEGORY,
            items: [
              {
                name: CATEGORY_ITEM,
                iconClassName: "pi pi-tags",
                to: REDIRECT_PATH.CATEGORY,
                isMenuActive: false,
              },
              {
                name: CATEGORY_ADD,
                iconClassName: "pi pi-plus",
                to: `${REDIRECT_PATH.CATEGORY}/add`,
                isMenuActive: false,
              },
            ],
          },
          {
            title: STORE_TITLE,
            items: [
              {
                name: STORE_ITEM,
                iconClassName: "pi pi-briefcase",
                to: REDIRECT_PATH.STORE,
                isMenuActive: false,
              },
            ],
          },
          {
            title: DETAILS_PAGE_TITLE,
            items: [
              {
                name: DETAILS_MASTER_PAGE_ITEM,
                iconClassName: "pi pi-briefcase",
                to: REDIRECT_PATH.DETAILS_MASTER_PAGE,
                isMenuActive: false,
              },
              {
                name: DETAILS_PAGE_ITEM,
                iconClassName: "pi pi-briefcase",
                to: REDIRECT_PATH.DETAILS_PAGE,
                isMenuActive: false,
              },
            ],
          },
          {
            title: BANNER_SLIDER,
            items: [
              {
                name: BANNER_SLIDER_ITEM,
                iconClassName: "pi pi-tags",
                to: REDIRECT_PATH.SLIDER,
                isMenuActive: false,
              },
              {
                name: BANNER_BANNER_LIST,
                iconClassName: "pi pi-tags",
                to: REDIRECT_PATH.BANNER,
                isMenuActive: false,
              },
              {
                name: BANNER_SLIDER_ADD,
                iconClassName: "pi pi-plus",
                to: `${REDIRECT_PATH.SLIDER}/add`,
                isMenuActive: false,
              },
            ],
          },
          {
            title: PROMOCODE,
            items: [
              {
                name: PROMOCODE_LIST,
                iconClassName: "pi pi-tags",
                to: REDIRECT_PATH.PROMOCODE,
                isMenuActive: false,
              },
              {
                name: PROMOCODE_ADD,
                iconClassName: "pi pi-plus",
                to: `${REDIRECT_PATH.PROMOCODE}/add`,
              },
            ],
          },
          {
            title: DEALOFTHEDAY,
            items: [
              {
                name: DEALOFTHEDAY_LIST,
                iconClassName: "pi pi-tags",
                to: REDIRECT_PATH.DEALOFTHEDAY,
                isMenuActive: false,
              },
              {
                name: DEALOFTHEDAY_ADD,
                iconClassName: "pi pi-plus",
                to: `${REDIRECT_PATH.DEALOFTHEDAY}/add`,
                isMenuActive: false,
              },
            ],
          },
          {
            title: ORDER_SETTING,
            items: [
              {
                name: ORDER_sETTING_LIST,
                iconClassName: "pi pi-tags",
                to: REDIRECT_PATH.ORDER_SETTING,
                isMenuActive: false,
              },
            ],
          },
          {
            title: FEATURE_,
            items: [
              {
                name: FEATURE_ITEM,
                iconClassName: "pi pi-cog",
                to: REDIRECT_PATH.FEATURE,
                isMenuActive: false,
              },
            ],
          },
          {
            title: INQUIRY,
            items: [
              {
                name: INQUIRY_ITEM,
                iconClassName: "pi pi-thumbs-up",
                to: REDIRECT_PATH.INQUIRY_,
                isMenuActive: false,
              },
            ],
          },
          {
            title: RIDER,
            items: [
              {
                name: RIDER_LIST,
                iconClassName: "pi pi-tags",
                to: REDIRECT_PATH.RIDER,
                isMenuActive: false,
              },
              {
                name: RIDER_ADD,
                iconClassName: "pi pi-plus",
                to: `${REDIRECT_PATH.RIDER}/add`,
                isMenuActive: false,
              },
            ],
          },
          {
            title: DELIVERY_SLOT,
            items: [
              {
                name: DELIVERY_SLOT_LIST,
                iconClassName: "pi pi-calendar-plus",
                to: REDIRECT_PATH.DELIVERY_SLIT_PATH,
                isMenuActive: false,
              },
            ],
          },
          {
            title: DELIVERY_OFF_DAY_,
            items: [
              {
                name: DELIVERY_OFF_DAY_LIST_,
                iconClassName: "pi pi-calendar-plus",
                to: REDIRECT_PATH.DELIVERY_OFF_DAY_,
                isMenuActive: false,
              },
            ],
          },
          {
            title: PINCODE,
            items: [
              {
                name: PINCODE_LIST_,
                iconClassName: "pi pi-map-marker",
                to: REDIRECT_PATH.PINCODE_PATH_,
                isMenuActive: false,
              },
              {
                name: DELIVERY_PINCODE_LIST,
                iconClassName: "pi pi-map-marker",
                to: REDIRECT_PATH.DELIVERY_PINCODE_PATH_,
                isMenuActive: false,
              },
            ],
          },
        ]
      : +localStorage.getItem("role") === Role.Store_Admin
      ? [
          {
            title: DASHBOARD_TITLE,
            items: [
              {
                name: DASHBOARD_ITEM,
                iconClassName: "pi pi-th-large",
                to: REDIRECT_PATH.DASHBOARD,
                isMenuActive: false,
              },
            ],
          },
          {
            title: USER_TITLE,
            items: [
              {
                name: USER_ITEM,
                iconClassName: "pi pi-user-plus",
                to: REDIRECT_PATH.USER,
                isMenuActive: false,
              },
            ],
          },
          {
            title: BRAND_TITLE,
            items: [
              {
                name: BRAND_ITEM,
                iconClassName: "pi pi-ticket",
                to: REDIRECT_PATH.BRAND_MASTER,
                isMenuActive: false,
              },
            ],
          },
          {
            title: ORDERS,
            items: [
              {
                name: ORDERS_ITEM,
                iconClassName: "pi pi-shopping-cart",
                to: REDIRECT_PATH.ORDERS,
                isMenuActive: false,
              },
            ],
          },
          {
            title: PRODUCTS,
            items: [
              {
                name: PRODUCTS_ITEM,
                iconClassName: "pi pi-shopping-bag",
                to: REDIRECT_PATH.PRODUCTS,
                isMenuActive: false,
              },
              {
                name: PRODUCTS_ADD,
                iconClassName: "pi pi-plus",
                to: "/products/add",
                isMenuActive: false,
              },
              {
                name: PRODUCTS_STOCK_ITEM,
                iconClassName: "pi pi-chart-bar",
                to: REDIRECT_PATH.PRODUCTS_STOCK,
                isMenuActive: false,
              },
              {
                name: PRODUCTS_STOCK__ADD,
                iconClassName: "pi pi-plus",
                to: `${REDIRECT_PATH.PRODUCTS_STOCK}/add`,
                isMenuActive: false,
              },
              {
                name: PRODUCTS_TAG_ITEM,
                iconClassName: "pi pi-tag",
                to: REDIRECT_PATH.PRODUCTS_TAG,
                isMenuActive: false,
              },
              {
                name: PRODUCTS_TAG_ADD,
                iconClassName: "pi pi-plus",
                to: `${REDIRECT_PATH.PRODUCTS_TAG}/add`,
                isMenuActive: false,
              },
            ],
          },
          {
            title: CATEGORY,
            items: [
              {
                name: CATEGORY_ITEM,
                iconClassName: "pi pi-tags",
                to: REDIRECT_PATH.CATEGORY,
                isMenuActive: false,
              },
              {
                name: CATEGORY_ADD,
                iconClassName: "pi pi-plus",
                to: `${REDIRECT_PATH.CATEGORY}/add`,
                isMenuActive: false,
              },
            ],
          },
          {
            title: STORE_TITLE,
            items: [
              {
                name: STORE_ITEM,
                iconClassName: "pi pi-briefcase",
                to: REDIRECT_PATH.STORE,
                isMenuActive: false,
              },
            ],
          },
          {
            title: PROMOCODE,
            items: [
              {
                name: PROMOCODE_LIST,
                iconClassName: "pi pi-tags",
                to: REDIRECT_PATH.PROMOCODE,
                isMenuActive: false,
              },
              {
                name: PROMOCODE_ADD,
                iconClassName: "pi pi-plus",
                to: `${REDIRECT_PATH.PROMOCODE}/add`,
              },
            ],
          },
          {
            title: DEALOFTHEDAY,
            items: [
              {
                name: DEALOFTHEDAY_LIST,
                iconClassName: "pi pi-tags",
                to: REDIRECT_PATH.DEALOFTHEDAY,
                isMenuActive: false,
              },
              {
                name: DEALOFTHEDAY_ADD,
                iconClassName: "pi pi-plus",
                to: `${REDIRECT_PATH.DEALOFTHEDAY}/add`,
                isMenuActive: false,
              },
            ],
          },
          {
            title: INQUIRY,
            items: [
              {
                name: INQUIRY_ITEM,
                iconClassName: "pi pi-thumbs-up",
                to: REDIRECT_PATH.INQUIRY_,
                isMenuActive: false,
              },
            ],
          },
          {
            title: RIDER,
            items: [
              {
                name: RIDER_LIST,
                iconClassName: "pi pi-tags",
                to: REDIRECT_PATH.RIDER,
                isMenuActive: false,
              },
              {
                name: RIDER_ADD,
                iconClassName: "pi pi-plus",
                to: `${REDIRECT_PATH.RIDER}/add`,
                isMenuActive: false,
              },
            ],
          },
          {
            title: DELIVERY_SLOT,
            items: [
              {
                name: DELIVERY_SLOT_LIST,
                iconClassName: "pi pi-calendar-plus",
                to: REDIRECT_PATH.DELIVERY_SLIT_PATH,
                isMenuActive: false,
              },
            ],
          },
          {
            title: DELIVERY_OFF_DAY_,
            items: [
              {
                name: DELIVERY_OFF_DAY_LIST_,
                iconClassName: "pi pi-calendar-plus",
                to: REDIRECT_PATH.DELIVERY_OFF_DAY_,
                isMenuActive: false,
              },
            ],
          },
        ]
      : [
          {
            name: "Temple Master",
            iconClassName: "pi pi-user-plus",
            to: "/templeMaster",
            isSubMenuActive: false,
          },
        ]
  );

  const LogoutToWebsite = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("role");
    localStorage.removeItem("orgId");
    history(REDIRECT_PATH.AUTH);
    dispatch(dropdownClose());
  };

  useEffect(() => {
    setMainDivSidebar(yes);
    // eslint-disable-next-line
  }, [yes]);

  const openAndCloseResponsiveSidebarShow = async (value) => {
    if (value === true) {
      setMobileSidebarOpenAndClose(value);
      await setMobileSidebar(value);
    } else if (value === false) {
      setMobileSidebarOpenAndClose(value);
      await setMobileSidebar(value);
    }
  };

  const handleClick = (i, index) => {
    SidebarMenu.map((manu, mmi) => {
      // main manu index
      return manu.items.map((elem, smi) => {
        // sub manu index
        if (i === mmi && smi === index && elem.isMenuActive === false) {
          elem.isMenuActive = true;
        } else {
          elem.isMenuActive = false;
        }
        return elem;
      });
    });
    setSibarMenu(SidebarMenu);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  isLoading
    ? (document.body.style.overflow = "hidden")
    : (document.body.style.overflow = null);
  return isLoading ? (
    <Loader />
  ) : (
    <>
      <div
        id={
          mobileSidebarOpenAndClose === true
            ? "mobile-View-sidebar-open"
            : mobileSidebarOpenAndClose === false &&
              windowSize.innerWidth <= 991.98
            ? "mobile-View-sidebar-close"
            : yes === true && yes !== undefined
            ? "mySidenav"
            : yes === false && yes !== undefined
            ? "mySidenav-static"
            : ""
        }
        className={`sidenav ${darkMode ? "dark-mode" : ""}`}
      >
        <div className="sidebar-header">
          {mobileSidebarOpenAndClose === true ? (
            <div onClick={() => openAndCloseResponsiveSidebarShow(false)}>
              <i
                className="pi pi-times
            "
              ></i>
            </div>
          ) : (
            <>
              <div
                onClick={() => setYes(!yes)}
                className={`${yes ? "sidebar-toggle-btn" : "fill-sidebar-btn"}`}
              >
                <i className="pi pi-th-large"></i>
              </div>
            </>
          )}
        </div>
        <ul>
          {SidebarMenu.map((elem, i) => {
            return (
              <div key={i}>
                <li
                  style={{
                    padding: "15px 25px",
                    fontSize: "13px",
                    textTransform: "uppercase",
                    fontWeight: "700",
                    color: "#6366f1",
                  }}
                  key={i}
                  className={`${
                    windowSize.innerWidth <= 991.98 ? "" : "show-title"
                  } ${darkMode ? "dark-side-title" : ""}`}
                >
                  {elem.title}
                </li>
                {elem.items?.map((item, index) => {
                  return (
                    <li key={index}>
                      <NavLink
                        to={item.to}
                        className={
                          darkMode
                            ? `${
                                item.isMenuActive
                                  ? "active-dark dark-static-href"
                                  : "dark-static-href"
                              }`
                            : `${
                                item.isMenuActive
                                  ? "active-min static-href"
                                  : "static-href"
                              }`
                        }
                        onClick={() => handleClick(i, index)}
                      >
                        <i
                          className={`${item.iconClassName} ${
                            darkMode ? "dark-side-title" : ""
                          }`}
                        ></i>
                        <span
                          style={{ fontSize: "14px" }}
                          className={`${
                            windowSize.innerWidth <= 991.98 ? "" : "show-title"
                          } ${darkMode ? "dark-side-menu" : ""} `}
                        >
                          {item.name}
                        </span>
                      </NavLink>
                      {item.submenu &&
                        item.submenu.map((subElem, i) => {
                          return (
                            <ul
                              key={i}
                              className={`
                                ${
                                  item.isMenuActive === true
                                    ? "submenu-open"
                                    : "submenu-close"
                                }`}
                            >
                              <li>
                                <NavLink
                                  className={(state) =>
                                    darkMode
                                      ? state.isActive
                                        ? "dark-active-sub"
                                        : ""
                                      : state.isActive
                                      ? "active-sub"
                                      : ""
                                  }
                                  to={subElem.to}
                                >
                                  <span>
                                    <i className={subElem.iconClassName}></i>
                                  </span>
                                  {subElem.name}
                                </NavLink>
                              </li>
                            </ul>
                          );
                        })}
                    </li>
                  );
                })}
              </div>
            );
          })}
        </ul>
      </div>
      <div className="layout-topbar-right">
        <ul className={`${darkMode ? "dark-nav" : ""}`}>
          <li onClick={() => openAndCloseResponsiveSidebarShow(true)}>
            <i className="pi pi-bars"></i>{" "}
          </li>
          <li>
            <div style={{ display: "flex" }}>
              <div
                className="moon-light-btn"
                onClick={() => setDarkMode(!darkMode)}
              >
                <i
                  className="pi pi-moon"
                  style={{
                    fontSize: "20px",
                    color: darkMode ? "rgb(255 255 255 / 60%)" : "#565656",
                  }}
                ></i>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  onClick={() => dispatch(dopdownOpen())}
                  className="d-flex gap-3 "
                  style={{ cursor: "pointer" }}
                >
                  <div
                    style={{
                      objectFit: "contain",
                      maxWidth: "35px",
                      height: "35px",
                      width: "100%",
                      display: "inline-block",
                      verticalAlign: "middle",
                      overflow: "hidden",
                      position: "relative",
                    }}
                  >
                    <img
                      src="https://bytebiz.fra1.digitaloceanspaces.com/dev-e-comm/user/10141692425516774.jpg"
                      alt=""
                      style={{
                        objectPosition: "50% 50%",
                        // objectFit: "cover",
                        opacity: "1",
                        transition: "opacity 0.28s ease-in",
                        width: "100%",
                        height: "100%",
                        borderRadius: "100%",
                      }}
                    />
                  </div>
                  <div className="d-flex flex-column ">
                    <span
                      style={{
                        fontWeight: "700",
                        color: "#515C66",
                        fontSize: "12px",
                      }}
                    >
                      {localStorage.getItem("loginName") || "admin"}
                    </span>
                    <small
                      style={{
                        color: "#515C66",
                        fontSize: "10px",
                      }}
                    >
                      {GetRole({ role: localStorage.getItem("role") }) ||
                        "admin"}
                    </small>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={headerDropdown ? "overlay-dropdown modal-in-drop" : ""}
              onClick={() => dispatch(dropdownClose())}
            ></div>
            <ul
              className={`${
                headerDropdown ? "inDropdown" : "inDropdown-close"
              }`}
            >
              <li>Profile</li>
              <li>Message</li>
              <li>Setting</li>
              <li
                style={{ marginBottom: "3px" }}
                onClick={() => LogoutToWebsite()}
              >
                Logout
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <Footer maindivSidebar={maindivSidebar} windowSize={windowSize} />
    </>
  );
};

export default Navbar;
