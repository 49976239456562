import React, { Fragment, useContext, useState } from "react";
import { FormDataFeild } from "../../../../reuseable/Inputs";
import { useMutation, useQuery } from "react-query";
import { IsLoadingResponse, slugUrlConvert } from "../../../../utils/Helper";
import {
  Submit,
  TYPE_FELDS,
  TITLE,
  PAGE_URL,
  SEO_Title,
  SEO_Keyword,
  SEO_Desc,
  ORDER,
  DETAILS_MASTER,
  HYPER_LINK,
} from "../../../../constants";
import CKEditors from "react-ckeditor-component";
import { Button } from "primereact/button";
import { Col, Form, Row } from "react-bootstrap";
import { Card, CardBody } from "reactstrap";
import { createDetailsPageSchema } from "../../../../utils/validationSchema";
import {
  Create_Details_Page,
  Put_Update_Details_Page,
} from "../../../../Apis/detailsPageApi/DetailsPageApi";
import DetailsPageContext from "../../../../context/DetailsPageContext/DetailsPageContext";
import { get_Details_Master_Page } from "../../../../Apis/detailsMasterPageApi/DetailsMasterPageApi";

const DetailsPageCreateFormPopup = (props) => {
  const { setFormData, id, isEdit, setdialogs, formData, errors, setErrors } =
    useContext(DetailsPageContext);
  const { refetch, emptyField } = props;
  const [detailsPageMasterFilter, setDetailsPageMasterFilter] = useState("");

  /**
   *
   * @param {event } e
   * create details page  & update details page
   */

  const { mutate: createDetailsPage, isLoading: createDetailsPageLoader } =
    useMutation((formData) =>
      Create_Details_Page(formData, refetch, setdialogs, emptyField)
    );

  const { mutate: updateDetailsPage, isLoading: updateDetailsPageLoader } =
    useMutation((formData) =>
      Put_Update_Details_Page(formData, id, refetch, setdialogs, emptyField)
    );

  const handleDetailsPageCreateAndUpdate = async (e) => {
    e.preventDefault();
    try {
      let schema = createDetailsPageSchema(formData.hyperLink);
      await schema.validate(formData, { abortEarly: false });
      if (id !== -1 && isEdit === false) {
        let newData = {
          ...formData,
          detailsMasterId: formData?.detailsMasterId?.code,
        };
        updateDetailsPage(newData);
      } else {
        let newData = {
          ...formData,
          detailsMasterId: formData?.detailsMasterId?.code,
        };
        createDetailsPage(newData);
      }
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((validationError) => {
        validationErrors[validationError.path] = validationError.message;
      });
      setErrors(validationErrors);
      if (Object.keys(validationErrors)[0]) {
        const inputField = document.getElementsByName(
          Object.keys(validationErrors)[0]
        );
        inputField[0].focus();
      }
    }
  };

  /**
   *
   * @param {get {value} and {name}} e
   * input value change
   */

  const handleChangeDetailsPage = (e) => {
    const { name, value } = e.target;
    if (name === "title") {
      setFormData((prev) => {
        return {
          ...prev,
          [name]: value,
          pageUrl: slugUrlConvert({ value }),
          seoTitle: value,
          seoKeyword: value,
          seoDesc: value,
        };
      });
    } else if (name === "pageUrl") {
      setFormData((prev) => {
        return {
          ...prev,
          [name]: slugUrlConvert({ value }),
        };
      });
    } else {
      setFormData((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    }
  };

  /**
   * change editor value
   * @param {value} evt
   */
  const onChange = (evt) => {
    const newContent = evt.editor.getData();
    setFormData((prev) => {
      return {
        ...prev,
        description: newContent,
      };
    });
  };

  /**
   * get details master page list
   */

  let paramsObj = {
    search: JSON.stringify({ isActive: true }),
  };
  if (detailsPageMasterFilter.length) {
    paramsObj["search"] = JSON.stringify({
      ...paramsObj.search,
      title: detailsPageMasterFilter,
    });
  }

  let { data: detailsMasterPage } = useQuery(
    ["getDetailsMasterPageDrop", paramsObj],
    async () => await get_Details_Master_Page(paramsObj),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      refetchOnReconnect: false,
    }
  );

  return (
    <Fragment>
      <IsLoadingResponse
        isLoading={createDetailsPageLoader || updateDetailsPageLoader}
      />
      <Row>
        <Col sm="12">
          <Card>
            <CardBody>
              <Form
                className="needs-validation"
                onSubmit={(e) => handleDetailsPageCreateAndUpdate(e)}
              >
                <Row>
                  <Col md="3 mb-4">
                    <FormDataFeild
                      label={`${TITLE} *`}
                      name="title"
                      id="title"
                      type={TYPE_FELDS.text}
                      placeholder={`${TITLE} *`}
                      value={formData.title}
                      handleChange={handleChangeDetailsPage}
                    />
                    {errors.title && (
                      <span className="error-validation">{errors.title}</span>
                    )}
                  </Col>
                  <Col md="3 mb-4">
                    <FormDataFeild
                      label={`${PAGE_URL} *`}
                      name="pageUrl"
                      id="pageUrl"
                      type={TYPE_FELDS.text}
                      placeholder={`${PAGE_URL} *`}
                      value={formData.pageUrl}
                      handleChange={handleChangeDetailsPage}
                    />
                    {errors.pageUrl && (
                      <span className="error-validation">{errors.pageUrl}</span>
                    )}
                  </Col>
                  <Col md="3 mb-4">
                    <FormDataFeild
                      label={`${DETAILS_MASTER} *`}
                      name="detailsMasterId"
                      id="detailsMasterId"
                      placeholder={`${DETAILS_MASTER} *`}
                      value={formData.detailsMasterId}
                      handleChange={handleChangeDetailsPage}
                      dropdown={true}
                      option={detailsMasterPage?.payload?.data?.map((elem) => {
                        return {
                          name: elem?.title,
                          code: elem?.detailsMasterId,
                        };
                      })}
                      search={(e) => {
                        setDetailsPageMasterFilter(e.target.value);
                      }}
                    />
                    {errors.detailsMasterId && (
                      <span className="error-validation">
                        {errors.detailsMasterId}
                      </span>
                    )}
                  </Col>
                  <Col md="3 mb-4">
                    <FormDataFeild
                      label={`${HYPER_LINK}`}
                      name="hyperLink"
                      id="hyperLink"
                      type={TYPE_FELDS.text}
                      placeholder={`${HYPER_LINK}`}
                      value={formData.hyperLink}
                      handleChange={handleChangeDetailsPage}
                    />
                    {errors.hyperLink && (
                      <span className="error-validation">
                        {errors.hyperLink}
                      </span>
                    )}
                  </Col>
                  <Col md="3 mb-4">
                    <FormDataFeild
                      label={`${ORDER} *`}
                      name="order"
                      id="order"
                      type={TYPE_FELDS.number}
                      placeholder={`${ORDER} *`}
                      value={formData.order}
                      handleChange={handleChangeDetailsPage}
                    />
                    {errors.order && (
                      <span className="error-validation">{errors.order}</span>
                    )}
                  </Col>
                  <Col md="3 mb-4">
                    <FormDataFeild
                      label={`${SEO_Title} *`}
                      name="seoTitle"
                      id="seoTitle"
                      type={TYPE_FELDS.text}
                      placeholder={`${SEO_Title} *`}
                      value={formData.seoTitle}
                      handleChange={handleChangeDetailsPage}
                    />
                    {errors.seoTitle && (
                      <span className="error-validation">
                        {errors.seoTitle}
                      </span>
                    )}
                  </Col>
                  <Col md="3 mb-4">
                    <FormDataFeild
                      label={`${SEO_Keyword} *`}
                      name="seoKeyword"
                      id="seoKeyword"
                      type={TYPE_FELDS.text}
                      placeholder={`${SEO_Keyword} *`}
                      value={formData.seoKeyword}
                      handleChange={handleChangeDetailsPage}
                    />
                    {errors.seoKeyword && (
                      <span className="error-validation">
                        {errors.seoKeyword}
                      </span>
                    )}
                  </Col>
                  <Col md="3 mb-4">
                    <FormDataFeild
                      label={`${SEO_Desc} *`}
                      name="seoDesc"
                      id="seoDesc"
                      type={TYPE_FELDS.text}
                      placeholder={`${SEO_Desc} *`}
                      value={formData.seoDesc}
                      handleChange={handleChangeDetailsPage}
                    />
                    {errors.seoDesc && (
                      <span className="error-validation">{errors.seoDesc}</span>
                    )}
                  </Col>
                  <Col md="12 mb-4">
                    <CKEditors
                      activeclassName="p10"
                      content={formData.description}
                      events={{
                        change: onChange,
                      }}
                    />
                    {errors.description && (
                      <span className="error-validation">
                        {errors.description}
                      </span>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md="12 mb-4" className="btn-style">
                    <Button
                      label={Submit}
                      icon="pi pi-check"
                      loading={
                        createDetailsPageLoader || updateDetailsPageLoader
                      }
                      type={TYPE_FELDS.submit}
                    />
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default DetailsPageCreateFormPopup;
