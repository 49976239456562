import React, { useContext } from "react";
import { Logout, ToastMessage } from "../../../../utils/Helper";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import DetailsPageDataTabelContainer from "./DetailsPageDataTabelContainer";
import DetailsPageCreateFormPopup from "./DetailsPageCreateFormPopup";
import Breadcrumb from "../../../../reuseable/Breadcrumb/Breadcrumb";
import {
  SLACE,
  DETAILS_PAGE,
  DETAILS_PAGE_LIST,
} from "../../../../reuseable/Breadcrumb/BreadcrumbConstant";
import Modal from "../../../../reuseableComponents/Modal";
import { CreateDetailsPage, UpdateDetailsPage } from "../../../../constants";
import { get_Details_Page } from "../../../../Apis/detailsPageApi/DetailsPageApi";
import DetailsPageContext from "../../../../context/DetailsPageContext/DetailsPageContext";
import CssContext from "../../../../context/css_context/CssContext/CssContext";
import { BackButton } from "../../../../reuseableComponents/buttons";

const DetailsPage = () => {
  const {
    //pagination state variables
    pageSize,
    page,
    setRecord,
    sortOrder,
    sortBy,
    filter,

    //store edit condition state and data object state
    setdialogs,
    dialog,
    setIsEdit,
    isEdit,
    setFormData,
  } = useContext(DetailsPageContext);
  const { isDashboardRedirect, setIsDashboardRedirect } =
    useContext(CssContext);

  const history = useNavigate();

  const emptyField = () => {
    setFormData({
      title: "",
      pageUrl: "",
      seoDesc: "",
      seoTitle: "",
      seoKeyword: "",
      description: "",
      order: "",
      detailsMasterId: null,
      hyperLink: "",
    });
  };

  /**
   * get details page list
   */

  let paramsObj = {
    pageNumber: page,
    recordsPerPage: pageSize,
    sortOrder,
    sortBy,
  };
  if (filter.length) {
    paramsObj["search"] = JSON.stringify({ title: filter });
  }

  let { data, refetch, isLoading, error } = useQuery(
    ["getDetailsPage", paramsObj],
    async () => await get_Details_Page(paramsObj),
    {
      onSuccess: (response) => {
        if (response.status === 200) {
          setRecord(response.pager.totalRecords);
        }
      },
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      refetchOnReconnect: false,
      retry: 3,
    }
  );

  if (error) {
    const { status, message } = error?.response?.data || error;
    ToastMessage(status, message);
    Logout(history, status);
  }

  /**
   * Open Popup and Close Popup details page
   */
  const handleOpenandClosePopup = (condition) => {
    setIsEdit(true);
    if (condition) {
      setdialogs(condition);
      emptyField();
    } else {
      setdialogs(condition);
      emptyField();
    }
  };

  const prev = () => {
    setIsDashboardRedirect({
      isShow: false,
      redirect: "",
    });
  };
  return (
    <>
      <Breadcrumb name={DETAILS_PAGE} slace={SLACE} name1={DETAILS_PAGE_LIST} />
      {isDashboardRedirect?.isShow && (
        <BackButton
          history={history}
          path={isDashboardRedirect.redirect}
          otherFunc={prev}
        />
      )}
      <DetailsPageDataTabelContainer
        isLoading={isLoading}
        data={data}
        handleOpenandClosePopup={handleOpenandClosePopup}
        refetch={refetch}
      />
      <Modal
        hide={handleOpenandClosePopup}
        visible={dialog}
        header={isEdit ? CreateDetailsPage : UpdateDetailsPage}
        Component={
          dialog && (
            <DetailsPageCreateFormPopup
              refetch={refetch}
              emptyField={emptyField}
            />
          )
        }
      />
    </>
  );
};

export default DetailsPage;
