import { Role } from "../constants";
import { getCookie } from "../utils/Helper";

export const checkTokenAndRole = () => {
  if (
    (localStorage.getItem("authToken") &&
      +localStorage.getItem("role") === Role.SuperAdmin) ||
    (getCookie("token") && +getCookie("roleId") === Role.SuperAdmin) ||
    (localStorage.getItem("authToken") &&
      +localStorage.getItem("role") === Role.OrgAdmin) ||
    (getCookie("token") && +getCookie("roleId") === Role.OrgAdmin)
  ) {
    return true;
  } else {
    return false;
  }
};

export const checkAndShowSidebar = () => {
  if (
    +localStorage.getItem("role") === Role.SuperAdmin ||
    +getCookie("roleId") === Role.SuperAdmin ||
    +localStorage.getItem("role") === Role.OrgAdmin ||
    +getCookie("roleId") === Role.OrgAdmin
  ) {
    return true;
  } else {
    return false;
  }
};
