import React, { useContext } from "react";
import { Logout, ToastMessage } from "../../../../utils/Helper";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import DeliveryOffDayDataTabelContainer from "./DeliveryOffDayDataTabelContainer";
import DeliveryOffDayCreateFormPopup from "./DeliveryOffDayCreateFormPopup";
import Breadcrumb from "../../../../reuseable/Breadcrumb/Breadcrumb";
import {
  DELIVERY_OFF_DAY,
  DELIVERY_OFF_DAY_LIST,
  SLACE,
} from "../../../../reuseable/Breadcrumb/BreadcrumbConstant";
import Modal from "../../../../reuseableComponents/Modal";
import { CreateDeliveryOff, UpdateDeliveryOff } from "../../../../constants";
import { get_Delivery_Off_Day } from "../../../../Apis/deliveryOffDayApi/DeliveryOffDayApi";
import DeliveryOffDayContext from "../../../../context/DeliveryOffDayContext/DeliveryOffDayContext";

const DeliveryOffDay = () => {
  const {
    //pagination state variables
    pageSize,
    page,
    setRecord,
    sortOrder,
    sortBy,
    filter,

    //delivery off day edit condition state and data object state
    setdialogs,
    dialog,
    setIsEdit,
    isEdit,
    setFormData,
    setErrors,
  } = useContext(DeliveryOffDayContext);

  const history = useNavigate();

  const emptyField = () => {
    setFormData({
      storeId: null,
      offDate: "",
      slotOffType: null,
      slotId: null,
    });
  };

  /**
   * get delivery off day List
   */

  let paramsObj = {
    pageNumber: page,
    recordsPerPage: pageSize,
    sortOrder,
    sortBy,
  };
  if (filter.length) {
    paramsObj["search"] = JSON.stringify({ title: filter });
  }

  let { data, refetch, isLoading, error } = useQuery(
    ["getDeliveryOffDay", paramsObj],
    async () => await get_Delivery_Off_Day(paramsObj),
    {
      onSuccess: (response) => {
        if (response.status === 200) {
          setRecord(response.pager.totalRecords);
        }
      },
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      refetchOnReconnect: false,
      retry: 3,
    }
  );

  if (error) {
    const { status, message } = error?.response?.data || error;
    ToastMessage(status, message);
    Logout(history, status);
  }

  /**
   * Open Popup and Close Popup delivery off day
   */
  const handleOpenandClosePopup = (condition) => {
    setIsEdit(true);
    if (condition) {
      setdialogs(condition);
      emptyField();
      setErrors({});
    } else {
      setdialogs(condition);
      emptyField();
      setErrors({});
    }
  };
  return (
    <>
      <Breadcrumb
        name={DELIVERY_OFF_DAY}
        slace={SLACE}
        name1={DELIVERY_OFF_DAY_LIST}
      />
      <DeliveryOffDayDataTabelContainer
        isLoading={isLoading}
        data={data}
        handleOpenandClosePopup={handleOpenandClosePopup}
        refetch={refetch}
      />
      <Modal
        hide={handleOpenandClosePopup}
        visible={dialog}
        header={isEdit ? CreateDeliveryOff : UpdateDeliveryOff}
        Component={
          <DeliveryOffDayCreateFormPopup
            refetch={refetch}
            emptyField={emptyField}
          />
        }
      />
    </>
  );
};

export default DeliveryOffDay;
