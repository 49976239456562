import React, { useContext } from "react";
import { Logout, ToastMessage } from "../../../../utils/Helper";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import PincodeMasterDataTabelContainer from "./PincodeMasterDataTabelContainer";
import PincodeMasterCreateFormPopup from "./PincodeMasterCreateFormPopup";
import Breadcrumb from "../../../../reuseable/Breadcrumb/Breadcrumb";
import {
  PINCODE,
  PINCODE_LIST,
  SLACE,
} from "../../../../reuseable/Breadcrumb/BreadcrumbConstant";
import Modal from "../../../../reuseableComponents/Modal";
import { CreatePincode, UpdatePincode } from "../../../../constants";
import PincodeMasterContext from "../../../../context/PincodeMasterContext/PincodeMasterContext";
import { get_Pincode_Master } from "../../../../Apis/pincodeMasterApi/PincodeMasterApi";

const PincodeMaster = () => {
  const {
    //pagination state variables
    pageSize,
    page,
    setRecord,
    sortOrder,
    sortBy,
    filter,

    //pincode master edit condition state and data object state
    setdialogs,
    dialog,
    setIsEdit,
    isEdit,
    setFormData,
    setErrors,
  } = useContext(PincodeMasterContext);

  const history = useNavigate();

  const emptyField = () => {
    setFormData({
      pincode: "",
      city: "",
      state: "",
      county: "",
      isAll: false,
    });
  };

  /**
   * get delivery off day List
   */

  let paramsObj = {
    pageNumber: page,
    recordsPerPage: pageSize,
    sortOrder,
    sortBy,
  };
  if (filter.length) {
    paramsObj["search"] = JSON.stringify({ pincode: filter });
  }

  let { data, refetch, isLoading, error } = useQuery(
    ["getPincode", paramsObj],
    async () => await get_Pincode_Master(paramsObj),
    {
      onSuccess: (response) => {
        if (response.status === 200) {
          setRecord(response.pager.totalRecords);
        }
      },
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      refetchOnReconnect: false,
      retry: 3,
    }
  );

  if (error) {
    const { status, message } = error?.response?.data || error;
    ToastMessage(status, message);
    Logout(history, status);
  }

  /**
   * Open Popup and Close Popup delivery off day
   */
  const handleOpenandClosePopup = (condition) => {
    setIsEdit(true);
    if (condition) {
      setdialogs(condition);
      emptyField();
      setErrors({});
    } else {
      setdialogs(condition);
      emptyField();
      setErrors({});
    }
  };
  return (
    <>
      <Breadcrumb name={PINCODE} slace={SLACE} name1={PINCODE_LIST} />
      <PincodeMasterDataTabelContainer
        isLoading={isLoading}
        data={data}
        handleOpenandClosePopup={handleOpenandClosePopup}
        refetch={refetch}
      />
      <Modal
        hide={handleOpenandClosePopup}
        visible={dialog}
        header={isEdit ? CreatePincode : UpdatePincode}
        Component={
          <PincodeMasterCreateFormPopup
            refetch={refetch}
            emptyField={emptyField}
          />
        }
      />
    </>
  );
};

export default PincodeMaster;
