import React, { Fragment, useContext, useMemo, useState } from "react";
import { FormDataFeild } from "../../../../reuseable/Inputs";
import { useMutation, useQuery } from "react-query";
import { IsLoadingResponse } from "../../../../utils/Helper";
import {
  Submit,
  TYPE_FELDS,
  Close,
  Product,
  QTY,
  MIN_STOCK,
  MAX_STOCK,
  NOTE,
  StoreName,
  ADD_MINUS,
  Total_Qty,
  TYPE,
} from "../../../../constants";
import { Button } from "primereact/button";
import { Col, Form, Row } from "react-bootstrap";
import { Card, CardBody } from "reactstrap";
import Breadcrumb from "../../../../reuseable/Breadcrumb/Breadcrumb";
import { createProductStockSchema } from "../../../../utils/validationSchema";
import {
  PRODUCT,
  PRODUCT_ADD,
  SLACE,
} from "../../../../reuseable/Breadcrumb/BreadcrumbConstant";
import { get_Products } from "../../../../Apis/productsApi/ProductsApi";
import { useNavigate, useParams } from "react-router-dom";
import { REDIRECT_PATH } from "../../../../Routes";
import ProductsStockContext from "../../../../context/ProductsStockContext/ProductsStockContext";
import {
  Create_Products_Stock,
  Get_Details_Products_Stock,
  Put_Update_Products_Stock,
} from "../../../../Apis/productsStockApi/ProductTagMasterStockApi";
import { get_Store_For_Add_Update } from "../../../../Apis/storeApi/StoreApi";
import { BackButton } from "../../../../reuseableComponents/buttons";

const ProductsStockCreateFormPopup = () => {
  const {
    setFormData,
    id,
    isEdit,
    formData,
    errors,
    setErrors,
    setId,
    setIsEdit,
  } = useContext(ProductsStockContext);
  const history = useNavigate();
  const [filterDrop, setFilterDrop] = useState({
    productFilter: "",
    storeFilter: "",
  });

  let { stockId } = useParams();
  /**
   *
   * @param {event } e
   * create product stock & update product stock
   */

  const { mutate: createProductsStock, isLoading: createProductsStockLoader } =
    useMutation((formData) =>
      Create_Products_Stock(formData, history, setFormData)
    );

  const { mutate: updateProductsStock, isLoading: updateProductsStockLoader } =
    useMutation((formData) =>
      Put_Update_Products_Stock(formData, id, history, setFormData)
    );

  const handleProductsStockCreateAndUpdate = async (e) => {
    e.preventDefault();
    try {
      let schema = createProductStockSchema();
      await schema.validate(formData, { abortEarly: false });
      if (stockId !== "add" && stockId !== -1 && isEdit === false) {
        let newData = {
          ...formData,
          productId: formData?.productId?.code,
          stocks: formData?.stocks.map((elem) => {
            return {
              storeId: elem.storeId,
              qty: elem?.qty ? elem?.qty : 0,
              minStock: elem?.minStock ? elem?.minStock : 0,
              maxStock: elem?.minStock ? elem?.minStock : 0,
              note: "",
            };
          }),
        };
        if (formData?.productId?.attributePricingMapId) {
          newData.attributePricingMapId =
            formData?.productId?.attributePricingMapId;
        }
        updateProductsStock(newData);
      } else {
        let newData = {
          ...formData,
          productId: formData?.productId?.code,
          stocks: formData?.stocks.map((elem) => {
            return {
              storeId: elem?.storeId,
              qty: elem?.qty ?? 0,
              minStock: elem?.minStock ?? 0,
              maxStock: elem?.maxStock ?? 0,
              type: elem?.type ? +elem.type.code : 1,
              totalQty: elem?.totalQty ?? null,
              note: elem?.note ?? null,
            };
          }),
        };
        if (formData?.productId?.attributePricingMapId) {
          newData.attributePricingMapId =
            formData?.productId?.attributePricingMapId;
        }
        createProductsStock(newData);
      }
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((validationError) => {
        validationErrors[validationError.path] = validationError.message;
      });
      setErrors(validationErrors);
      if (Object.keys(validationErrors)[0]) {
        const inputField = document.getElementsByName(
          Object.keys(validationErrors)[0]
        );
        inputField[0].focus();
      }
    }
  };

  /**
   *
   * @param {get {value} and {name}} e
   * input value change
   */

  const handleChangeStock = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  const handleChangeMultipleStock = (e, i) => {
    const { name, value } = e.target;
    let old = [...formData.stocks];
    if (name === "type") {
      if (+value?.code === 1) {
        // 1 = credit
        let subValue = 0;
        subValue = +old[i].copyTotalQty + +old[i].qty;
        old[i]["totalQty"] = subValue;
      } else if (+value?.code === 2) {
        // 2 = debit
        let subValue = 0;
        subValue = +old[i].copyTotalQty - +old[i].qty;
        old[i]["totalQty"] = Math?.max(subValue, 0);
      } else if (value === undefined || value === null || value === "") {
        old[i]["totalQty"] = old[i].copyTotalQty;
      }
      old[i][name] = value;
    } else if (
      name === "qty" &&
      (+old[i]?.type?.code === 1 || +old[i]?.type?.code === 2)
    ) {
      if (+old[i]?.type?.code === 1) {
        let subValue = 0;
        if (value !== undefined) {
          subValue = +old[i].copyTotalQty + +value;
          old[i]["totalQty"] = subValue;
        } else {
          old[i]["totalQty"] = +old[i].copyTotalQty;
        }
      } else if (+old[i]?.type?.code === 2) {
        let subValue = 0;
        if (old[i].copyTotalQty === 0) {
          old[i]["totalQty"] = +old[i].copyTotalQty;
        } else {
          subValue = +old[i].copyTotalQty - +value;
          old[i]["totalQty"] = subValue;
        }
      }
    } else if (name === "qty") {
      if (value >= 0) {
        old[i][name] = value;
      } else {
        old[i][name] = "";
      }
    } else {
      old[i][name] = value;
    }
    setFormData({ ...formData, stocks: old });
  };

  /**
   * if stockId is available to  refresh page to field fill api call
   */
  const { mutate: getProductStockDetails } = useMutation((stockId) =>
    Get_Details_Products_Stock(stockId, history, setFormData)
  );

  useMemo(() => {
    let valueOfNumber = Number(stockId);
    if (stockId !== "add" && typeof valueOfNumber === "number") {
      getProductStockDetails(stockId);
      setId(stockId);
      setIsEdit(false);
    }
    // eslint-disable-next-line
  }, [stockId]);

  /**
   * get product and store dropdown data api call
   */

  let paramsObj = {
    showAll: true,
    search: JSON.stringify({ productName: filterDrop.productFilter }),
  };
  let { data } = useQuery(
    ["getProductDrop", paramsObj],
    async () => await get_Products(paramsObj),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const { mutate: storeDropFunc } = useMutation((paramsStoreObj) =>
    get_Store_For_Add_Update(paramsStoreObj, setFormData)
  );

  useMemo(() => {
    if (formData.productId?.code) {
      let paramsStoreObj = {
        showAll: true,
        productId: formData.productId?.code,
      };
      storeDropFunc(paramsStoreObj);
    }
    // eslint-disable-next-line
  }, [formData.productId?.code]);

  /**
   * go to parent page
   */
  const redirect = () => {
    history(REDIRECT_PATH.PRODUCTS_STOCK);
    setFormData({ productId: "", stocks: [] });
  };
  return (
    <Fragment>
      <Breadcrumb name={PRODUCT} slace={SLACE} name1={PRODUCT_ADD} />
      <IsLoadingResponse
        isLoading={createProductsStockLoader || updateProductsStockLoader}
      />
      <BackButton
        history={history}
        path={REDIRECT_PATH.PRODUCTS_STOCK}
        otherFunc={redirect}
      />
      <div className="card-div">
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form
                  className="needs-validation"
                  onSubmit={(e) => handleProductsStockCreateAndUpdate(e)}
                >
                  <Row>
                    <Col md="12 mb-4">
                      <FormDataFeild
                        label={Product}
                        name="productId"
                        id="productId"
                        placeholder={Product}
                        value={formData.productId}
                        handleChange={handleChangeStock}
                        dropdown={true}
                        option={data?.payload?.data?.flatMap((elem) => {
                          if (elem.prodType === "attribute") {
                            return elem.productAttributes.flatMap((attElem) => {
                              return attElem.attributePricingMaps.flatMap(
                                (attValueElem) => {
                                  return {
                                    code: attElem?.productId,
                                    name: `${elem?.productName} (${attValueElem?.attributeDetail?.value})`,
                                    attributePricingMapId:
                                      attValueElem.attributePricingMapId,
                                  };
                                }
                              );
                            });
                          } else {
                            return {
                              code: elem?.productId,
                              name: elem?.productName,
                            };
                          }
                        })}
                        search={(e) =>
                          setFilterDrop((prev) => {
                            return {
                              ...prev,
                              productFilter: e.target.value,
                            };
                          })
                        }
                      />
                      {errors.productId && (
                        <span className="error-validation">
                          {errors.productId}
                        </span>
                      )}
                    </Col>
                    {formData?.stocks?.map((elem, i) => {
                      return (
                        <>
                          <Col md="2 mb-4">
                            <FormDataFeild
                              label={StoreName}
                              name="storeName"
                              id="storeName"
                              type={TYPE_FELDS.text}
                              placeholder={StoreName}
                              value={elem.storeName}
                              handleChange={(e) =>
                                handleChangeMultipleStock(e, i)
                              }
                              disabled={true}
                            />
                          </Col>
                          <Col md="1 mb-4">
                            <FormDataFeild
                              label={QTY}
                              name="qty"
                              id="qty"
                              type={TYPE_FELDS.number}
                              placeholder={QTY}
                              value={elem.qty}
                              handleChange={(e) =>
                                handleChangeMultipleStock(e, i)
                              }
                            />
                          </Col>
                          <Col md="2 mb-4">
                            <FormDataFeild
                              label={ADD_MINUS}
                              name="type"
                              id="type"
                              placeholder={ADD_MINUS}
                              value={elem.type}
                              handleChange={(e) =>
                                handleChangeMultipleStock(e, i)
                              }
                              dropdown={true}
                              option={TYPE}
                              filter={false}
                            />
                          </Col>
                          <Col md="1 mb-4">
                            <FormDataFeild
                              label={Total_Qty}
                              name="totalQty"
                              id="totalQty"
                              type={TYPE_FELDS.number}
                              placeholder={Total_Qty}
                              value={elem.totalQty}
                              handleChange={(e) =>
                                handleChangeMultipleStock(e, i)
                              }
                              disabled={true}
                            />
                          </Col>
                          <Col md="1 mb-4">
                            <FormDataFeild
                              label={MIN_STOCK}
                              name="minStock"
                              id="minStock"
                              type={TYPE_FELDS.number}
                              placeholder={MIN_STOCK}
                              value={elem.minStock}
                              handleChange={(e) =>
                                handleChangeMultipleStock(e, i)
                              }
                            />
                          </Col>
                          <Col md="1 mb-4">
                            <FormDataFeild
                              label={MAX_STOCK}
                              name="maxStock"
                              id="maxStock"
                              type={TYPE_FELDS.number}
                              placeholder={MAX_STOCK}
                              value={elem.maxStock}
                              handleChange={(e) =>
                                handleChangeMultipleStock(e, i)
                              }
                            />
                          </Col>
                          <Col md="4 mb-4">
                            <FormDataFeild
                              label={NOTE}
                              name="note"
                              id="note"
                              type={TYPE_FELDS.text}
                              placeholder={NOTE}
                              value={elem.note}
                              handleChange={(e) =>
                                handleChangeMultipleStock(e, i)
                              }
                            />
                          </Col>
                        </>
                      );
                    })}

                    {/* <Col md="4 mb-4">
                      <FormDataFeildTextArea
                        label={NOTE}
                        name="note"
                        id="note"
                        type={TYPE_FELDS.text}
                        placeholder={NOTE}
                        value={formData.note}
                        handleChange={handleChangeStock}
                      />
                    </Col> */}
                  </Row>
                  <Row>
                    <Col
                      md="12 mb-4"
                      className="btn-style flex justify-content-between "
                    >
                      <Button
                        label={Close}
                        severity="danger"
                        icon="pi pi-times"
                        onClick={() => redirect()}
                      />
                      <Button
                        label={Submit}
                        icon="pi pi-check"
                        loading={
                          createProductsStockLoader || updateProductsStockLoader
                        }
                        type={TYPE_FELDS.submit}
                      />
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default ProductsStockCreateFormPopup;
