import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAttributeClose } from "../../../../redux/slices/selectAttributePopup";
import { Fieldset } from "primereact/fieldset";
import { get_Category_Attribute_Map } from "../../../../Apis/categoryAttributeMapApi/CategoryAttributeMapApi";
import { useQuery } from "react-query";
import { Checkbox } from "primereact/checkbox";
import ProductsContext from "../../../../context/ProductsContext/ProductsContext";
import { randomStr } from "../../../../utils/Helper";

const SelectAttributes = () => {
  const open = useSelector((state) => state.select_attribute_popup);
  const dispatch = useDispatch();
  const {
    selectAttribute,
    setSelectAttribute,
    setSaveAttData,
    setFormData,
    formData,
    setSelectedAttributeCopy,
  } = useContext(ProductsContext);

  const footerContent = (
    <div>
      <Button
        label="Close"
        type="button"
        icon="pi pi-times"
        onClick={() => dispatch(selectAttributeClose())}
        className="p-button-text"
      />
      <Button
        label="Save"
        type="button"
        icon="pi pi-check"
        onClick={() => {
          setSaveAttData(true);
          dispatch(selectAttributeClose());
        }}
        autoFocus
      />
    </div>
  );

  let paramsCategoryAttributeMapObj = {
    showAll: true,
    search: JSON.stringify({ isActive: true }),
  };

  let { data: categoryAttributeMapDrop } = useQuery(
    ["getCategoryAttributeMapDrop", paramsCategoryAttributeMapObj],
    async () => await get_Category_Attribute_Map(paramsCategoryAttributeMapObj),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const handleAddCategoryAtt = ({
    attributeMasterId,
    name,
    attributeType,
    selectedAttribute,
  }) => {
    let copy = [...selectAttribute];
    if (selectedAttribute) {
      copy = copy?.filter((elem) => {
        if (elem !== undefined && elem !== null)
          return elem.categoryAtt.attributeMasterId !== selectedAttribute;
        else return elem;
      });
      setSelectAttribute(copy);
      setFormData((prev) => {
        return {
          ...prev,
          attributes: [
            {
              id: randomStr(2),
              attributeId: formData.attributes[0].attributeId.filter(
                (filter) => filter.attributeMasterId !== attributeMasterId
              ),
              attributeArr: "",
              attributeType: "",
              mrp: "",
              sellPrice: "",
              taxAmt: "",
              taxType: "",
              taxPer: "",
              isDefault: true,
            },
          ],
        };
      });
      return;
    } else {
      setSelectAttribute((prev) => {
        return [
          ...prev,
          {
            categoryAtt: { attributeMasterId, name, attributeType },
            attValue: [],
          },
        ];
      });
      setFormData((prev) => {
        return {
          ...prev,
          attributes: [
            {
              id: randomStr(2),
              attributeId: [
                ...prev.attributes[0].attributeId,
                { attributeMasterId },
              ],
              attributeArr: "",
              attributeType: "",
              mrp: "",
              sellPrice: "",
              taxAmt: "",
              taxType: "",
              taxPer: "",
              isDefault: true,
            },
          ],
        };
      });
    }
  };

  const handleAddCategoryAttItems = async ({
    event,
    name,
    attributeId,
    attributeMasterId,
  }) => {
    if (event.checked) {
      let copy = [...selectAttribute];
      for (let i = 0; i < copy.length; i++) {
        if (copy[i]?.categoryAtt?.attributeMasterId === attributeMasterId) {
          copy[i].attValue.push({ attributeId, name });
        }
      }
      setSelectAttribute(copy);
      setSelectedAttributeCopy(copy);
    } else {
      let copy = [...selectAttribute];
      for (let i = 0; i < copy.length; i++) {
        if (copy[i]?.categoryAtt?.attributeMasterId === attributeMasterId) {
          copy[i].attValue = copy[i].attValue.filter(
            (elem) => elem.attributeId !== attributeId
          );
        }
      }
      setSelectAttribute(copy);
      setSelectedAttributeCopy(copy);
    }
  };

  return (
    <Dialog
      header="Attribute"
      visible={open.attribute}
      position={"top-left"}
      onHide={() => dispatch(selectAttributeClose())}
      footer={footerContent}
      draggable={false}
      resizable={false}
      className="custom-device"
    >
      <div className="attribute-selection mt-5">
        {categoryAttributeMapDrop?.payload?.data?.map((elem, i) => {
          if (
            elem?.attributeMaster !== null &&
            elem?.attributeMaster !== undefined
          ) {
            return (
              <Fieldset
                legend={elem?.attributeMaster?.name}
                toggleable
                collapsed={
                  selectAttribute?.filter(
                    (fil) =>
                      fil?.categoryAtt.attributeMasterId ===
                      elem?.attributeMasterId
                  )[0]?.categoryAtt?.attributeMasterId ===
                  elem?.attributeMasterId
                    ? false
                    : true
                }
                onToggle={() =>
                  handleAddCategoryAtt({
                    attributeMasterId: elem?.attributeMasterId,
                    name: elem.attributeMaster?.name,
                    attributeType: elem.attributeMaster?.attributeType,
                    selectedAttribute: selectAttribute?.filter(
                      (fil) =>
                        fil?.categoryAtt.attributeMasterId ===
                        elem?.attributeMasterId
                    )[0]?.categoryAtt?.attributeMasterId,
                  })
                }
                className=" position-relative mb-5"
              >
                <div className="flex flex-wrap gap-3">
                  {elem?.attributeMaster?.attributesDetail?.map(
                    (childElem, index) => {
                      return (
                        <div className="flex align-items-center">
                          <Checkbox
                            inputId={childElem?.value}
                            name={childElem?.value}
                            value={childElem?.attributeId}
                            onChange={(e) =>
                              handleAddCategoryAttItems({
                                event: e,
                                name: childElem?.value,
                                attributeId: childElem?.attributeId,
                                attributeMasterId: elem?.attributeMasterId,
                              })
                            }
                            checked={
                              selectAttribute
                                .filter(
                                  (fil) =>
                                    fil?.categoryAtt.attributeMasterId ===
                                    elem?.attributeMasterId
                                )[0]
                                ?.attValue?.filter(
                                  (opFIl) =>
                                    opFIl.attributeId === childElem?.attributeId
                                )[0]?.attributeId === childElem?.attributeId
                            }
                          />
                          <label htmlFor="ingredient1" className="ml-2">
                            {childElem?.value}
                          </label>
                        </div>
                      );
                    }
                  )}
                </div>
              </Fieldset>
            );
          }
        })}
      </div>
    </Dialog>
  );
};

export default SelectAttributes;
