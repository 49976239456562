import axios from "axios";
import {
  CREATE_RIDER,
  DELETE_RIDER,
  EDIT_RIDER,
  EDIT_RIDER_LOCATION,
  ENABLE_DISABLE_RIDER,
  GET_RIDER,
  GET_RIDER_DATE_WISE_DELIVERED,
  GET_RIDER_DELIVERY_LOCATION,
  GET_RIDER_DETAILS,
  GET_RIDER_ONLINE_OFFLINE,
  GET_RIDER_WALLET_HISTORY,
} from "../../constants/ApiConstant";
import { ToastMessage } from "../../utils/Helper";
import { REDIRECT_PATH } from "../../Routes";
import { optionsDialCode } from "../../constants";
import { get_Store } from "../storeApi/StoreApi";

/**
 * get rider list data
 * @param {pagination params} paramsObj
 * @returns data response
 */

export const get_Rider = (paramsObj) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_RIDER}`, {
      headers: { Authorization: jwt_token },
      params: paramsObj,
    })
    .then((res) => res.data);
};

/**
 * get rider online offline list data
 * @param {pagination params} paramsObj
 * @returns data response
 */

export const get_Rider_Online_Offline = (paramsObj) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_RIDER_ONLINE_OFFLINE}`, {
      headers: { Authorization: jwt_token },
      params: paramsObj,
    })
    .then((res) => res.data);
};

/**
 * get rider wallet history list data
 * @param {pagination params} paramsObj
 * @returns data response
 */

export const get_Rider_Wallet_History = (paramsObj) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_RIDER_WALLET_HISTORY}`, {
      headers: { Authorization: jwt_token },
      params: paramsObj,
    })
    .then((res) => res.data);
};

/**
 * get rider date wise delivered data
 * @param {pagination params} paramsObj
 * @returns data response
 */

export const get_Rider_DATE_WISE_DELIVERED = (paramsObj) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_RIDER_DATE_WISE_DELIVERED}`, {
      headers: { Authorization: jwt_token },
      params: paramsObj,
    })
    .then((res) => res.data);
};

/**
 * get rider delivery location list data
 * @param {pagination params} paramsObj
 * @returns data response
 */

export const get_Rider_Delivery_Location = (paramsObj) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_RIDER_DELIVERY_LOCATION}`, {
      headers: { Authorization: jwt_token },
      params: paramsObj,
    })
    .then((res) => res.data.payload.data);
};

/**
 * add rider api call
 * @param {form data} formData
 * @param {rider list function} refetch
 * @param {modal close} setdialogs
 * @param {emptyData} emptyData
 * @returns
 */

export const Create_Rider = (formData, history, emptyData) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .post(`${process.env.REACT_APP_API_URL}${CREATE_RIDER}`, formData, {
      headers: { Authorization: jwt_token },
    })
    .then((message) => {
      emptyData();
      ToastMessage(message.status, message.data.message);

      history(`${REDIRECT_PATH.RIDER}`);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 *enable - disable rider api call
 * @param {form data as isActive & riderId} formData
 * @param {list api call function} refetch
 * @returns true & false
 */

export const Enable_Disable_Rider = (formData, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(`${process.env.REACT_APP_API_URL}${ENABLE_DISABLE_RIDER}`, formData, {
      headers: { Authorization: jwt_token },
      params: { riderId: formData.riderId },
    })
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      if (error.response) {
        const { status, message } = error?.response?.data || error;
        ToastMessage(status, message);
      }
    });
};

/**
 * get details rider
 * @param {*} riderId
 * @param {form data func} setFormData
 * @param {modal func} setdialogs
 * @returns
 */
export const Get_Details_Rider = (riderId, history, setFormData, setAspect) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_RIDER_DETAILS}`, {
      headers: { Authorization: jwt_token },
      params: { riderId },
    })
    .then(async (res) => {
      let data = res.data.payload.data;
      let paramObj = {
        showAll: true,
      };
      let responseStore = await get_Store(paramObj);
      [responseStore] = responseStore?.payload?.data?.filter(
        (elem) => elem?.storeId === data?.storeId
      );
      let [responseDialCode] = optionsDialCode.filter(
        (elem) => elem.code === data?.userDetail?.dialCode
      );
      setFormData({
        storeId: data ? data?.storeId : null,
        dialCode: responseDialCode,
        phone: data ? data?.userDetail?.phone : null,
        deviceId: data ? data?.deviceId : null,
        vehicleNumber: data ? data?.vehicleNumber : null,
        vehicleType: data ? data?.vehicleType : null,
        email: data ? data?.userDetail?.email : null,
        address: data ? data?.address : null,
        city: data ? data?.city : null,
        password: data ? data?.userDetail?.password : null,
        name: data ? data?.userDetail?.name : null,
        confirmPassword: data ? data?.userDetail?.confirmPassword : null,
        assignStores: data
          ? {
              name: responseStore?.name,
              code: responseStore?.storeId,
            }
          : null,
        // name: data ? data?.name : null,
        // productId: data
        //   ? {
        //     code: responseProduct?.productId,
        //     name: responseProduct?.productName,
        //   }
        //   : null,
        // catId: data
        //   ? {
        //     code: responseCategory.catId,
        //     name: responseCategory.name,
        //   }
        //   : null,
        // image: data ? data?.imageUrl : null,
        // caption: data ? data?.caption : null,
        // type: data ? responseSlider : null,
        // startDate: data
        //   ? [new Date(data?.startDate), new Date(data?.endDate)]
        //   : null,
        // endDate: data ? data?.endDate : null,
      });

      history(`${REDIRECT_PATH.RIDER}/${riderId}`);
    });
};

/**
 * update rider api call
 * @param {form data} formData
 * @param {*} riderId
 * @param {list api call } refetch
 * @param {modal func} setdialogs
 * @param {emptyData} emptyData
 * @returns
 */

export const Put_Update_Rider = (formData, riderId, history, emptyData) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(`${process.env.REACT_APP_API_URL}${EDIT_RIDER}`, formData, {
      headers: { Authorization: jwt_token },
      params: { riderId },
    })
    .then((message) => {
      emptyData();
      ToastMessage(message.status, message.data.message);
      history(`${REDIRECT_PATH.RIDER}`);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 * delete rider api call
 * @param {form data} formData
 * @param {*} brandId
 * @param {list api call } refetch
 * @returns
 */

export const Delete_Rider = (riderId, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .delete(`${process.env.REACT_APP_API_URL}${DELETE_RIDER}`, {
      headers: { Authorization: jwt_token },
      params: { riderId },
    })
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 * update rider api call
 * @param {form data} formData
 * @param {*} riderId
 * @param {list api call } refetch
 * @param {modal func} setdialogs
 * @param {emptyData} emptyData
 * @returns
 */

export const Put_Update_Rider_Location = (
  formData,
  riderId,
  history,
  emptyData
) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(`${process.env.REACT_APP_API_URL}${EDIT_RIDER_LOCATION}`, formData, {
      headers: { Authorization: jwt_token },
      params: { riderId },
    })
    .then((message) => {
      emptyData();
      ToastMessage(message.status, message.data.message);
      if (formData.type === 1) {
        history(`${REDIRECT_PATH.SLIDER}`);
      } else {
        history(`${REDIRECT_PATH.BANNER}`);
      }
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};
